@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');

:root {
    --r1-black: #101212; //UI color (Doesn't match branding 2018-12-19)
    --r1-white: #ffffff;
    --r1-gray: #54585a; //Branding color (as of 2018-12-19)
    --r1-warm-gray: #5b514e; //Branding color (as of 2018-12-19)
    --r1-smoke: #9da2a6; //Branding color (as of 2018-12-19)
    --r1-light-gray: #ebebeb; //UI color (Doesn't match branding 2018-12-19)
    --r1-faint-gray: #efefef; //Branding color (as of 2018-12-19)
    --r1-dark-blue: #01426a; //UI color (Doesn't match branding 2018-12-19)
    --r1-very-dark-blue: #01426a; //UI color (Doesn't match branding 2018-12-19)
    --r1-midnight-blue: #002e58; //Branding color (as of 2018-12-19)
    --r1-brilliant-blue: #1174b6; //UI color (Doesn't match branding 2018-12-19)
    --r1-brilliant-blue-2018: #0085ca; //UI color (as of 2018-10-25)
    --r1-light-blue: #8db9ca; //UI color (Doesn't match branding 2018-12-19)
    --r1-orange: #e87722; //UI color (Doesn't match branding 2018-12-19)
    --r1-orange-2018: #ff9933; //Branding color (as of 2018-12-19)
    --r1-red: #ef3742; //Branding color (as of 2018-12-19)
    --r1-green: #64a70b; //UI color (Doesn't match branding 2018-12-19)
    --r1-lime: #93d500; //Branding color (as of 2018-12-19)
    --r1-purple: #7d4c79; //Legacy branding color (Long live purple.)
    --r1-portal-ui-blue-gray: #36516c;

    --r1-field-merge-green: #41a85f;
    --r1-field-merge-dark-green: #2b6e3e;

    --r1-overlay: rgba(58, 39, 39, 0.5);

    --r1-font-family: 'Open Sans', Arial, Helvetica, sans-serif;

    --r1-font-10: 10px;
    --r1-font-12: 12px;
    --r1-font-14: 14px;
    --r1-font-16: 16px;
    --r1-font-18: 18px;
    --r1-font-20: 20px;
    --r1-font-24: 24px;
    --r1-font-36: 36px;
    --r1-font-60: 60px;
}

$r1-font-family: var(--r1-font-family);