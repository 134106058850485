@use '@angular/material' as mat;
@include mat.core();

$r1-black: #101212; //UI color (Doesn't match branding 2018-12-19)
$r1-white: #ffffff;
$r1-gray: #54585a; //Branding color
$r1-warm-gray: #5b514e; //Branding color
$r1-smoke: #9da2a6; //Branding color
$r1-light-gray: #ebebeb; //UI color (Doesn't match branding 2018-12-19)
$r1-faint-gray: #efefef; //Branding color
$r1-dark-blue: #01426a; //UI color (Doesn't match branding 2018-12-19)
$r1-brilliant-blue: #1174b6; //UI color (Doesn't match branding 2018-12-19)
$r1-light-blue: #8db9ca; //UI color (Doesn't match branding 2018-12-19)
$r1-orange: #e87722; //UI color (Doesn't match branding 2018-12-19)
$r1-red: #ef3742; //Branding color
$r1-green: #64a70b; //UI color (Doesn't match branding 2018-12-19)
$r1-purple: #7d4c79; //Legacy branding color (Long live purple.)

$r1-palatte-blue: (
    50: $r1-white,
    100: $r1-light-gray,
    200: $r1-light-blue,
    300: $r1-light-blue,
    400: $r1-brilliant-blue,
    500: $r1-brilliant-blue,
    600: $r1-brilliant-blue,
    700: $r1-brilliant-blue,
    800: $r1-dark-blue,
    900: $r1-dark-blue,
    A100: $r1-white,
    A200: $r1-light-gray,
    A400: $r1-brilliant-blue,
    A700: $r1-dark-blue,
    contrast: (
        50: $r1-black,
        100: $r1-black,
        200: $r1-black,
        300: $r1-black,
        400: $r1-black,
        500: $r1-white,
        600: $r1-white,
        700: $r1-white,
        800: $r1-white,
        900: $r1-white,
        A100: $r1-black,
        A200: $r1-black,
        A400: $r1-black,
        A700: $r1-white
    )
);

$r1-palatte-orange: (
    50: $r1-white,
    100: $r1-light-gray,
    200: $r1-orange,
    300: $r1-orange,
    400: $r1-orange,
    500: $r1-orange,
    600: $r1-orange,
    700: $r1-orange,
    800: $r1-red,
    900: $r1-red,
    A100: $r1-white,
    A200: $r1-light-gray,
    A400: $r1-orange,
    A700: $r1-red,
    contrast: (
        50: $r1-black,
        100: $r1-black,
        200: $r1-black,
        300: $r1-black,
        400: $r1-black,
        500: $r1-white,
        600: $r1-white,
        700: $r1-white,
        800: $r1-white,
        900: $r1-white,
        A100: $r1-black,
        A200: $r1-black,
        A400: $r1-black,
        A700: $r1-white
    )
);

$r1-palatte-green: (
    50: $r1-white,
    100: $r1-light-gray,
    200: $r1-green,
    300: $r1-green,
    400: $r1-green,
    500: $r1-green,
    600: $r1-green,
    700: $r1-green,
    800: $r1-green,
    900: $r1-green,
    A100: $r1-white,
    A200: $r1-light-gray,
    A400: $r1-green,
    A700: $r1-green,
    contrast: (
        50: $r1-black,
        100: $r1-black,
        200: $r1-black,
        300: $r1-black,
        400: $r1-black,
        500: $r1-white,
        600: $r1-white,
        700: $r1-white,
        800: $r1-white,
        900: $r1-white,
        A100: $r1-black,
        A200: $r1-black,
        A400: $r1-black,
        A700: $r1-white
    )
);

$r1-primary: mat.m2-define-palette($r1-palatte-blue, 500, 300, 900);
$r1-accent: mat.m2-define-palette($r1-palatte-green, 500, 300, 900);
$r1-warn: mat.m2-define-palette($r1-palatte-orange, 500, 300, 900);

$r1-theme: mat.m2-define-light-theme((
    color: (
        primary: $r1-primary,
        accent: $r1-accent,
        warn: $r1-warn
    )
));

@include mat.all-component-themes($r1-theme);

// TODO - check [color] binding, like change-config-warning
// TODO in ng19: mdc3-ify
.r1-btn-black:not(:disabled),
.mat-fab.mat-black:not(:disabled),
.mat-flat-button.mat-black:not(:disabled),
.mat-mini-fab.mat-black:not(:disabled),
.mat-raised-button.mat-black:not(:disabled) {
    background-color: $r1-black !important;
    color: $r1-white !important;
}

.mat-button.mat-black,
.mat-icon-button.mat-black,
.mat-stroked-button.mat-black {
    color: $r1-black;
}

.mat-mdc-dialog-title,
.mat-mdc-dialog-content {
    font-family: var(--r1-font-family);
}

.mat-mdc-dialog-title {
    margin: 0;
}

table.mat-mdc-table {
    width: 100%;
}

td, th {
    padding: 0 10px 0 0;
}

// TODO - truly needed? https://github.com/relationshipone/r1-frontend/commit/c19be94efc57e0268a958b3efa0b8a8699081f51#diff-e14ae37b78db7c36244f3a1c6d79942afe849f02db125a26f2825dd5e59d28a5
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: 0px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
    top: 0px;
}

.generic-snack-bar-warning {
    @include mat.snack-bar-overrides((
        container-color: var(--r1-orange),
        supporting-text-color: var(--r1-white),
    ));
}
