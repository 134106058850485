:root {
    --facebook-blue: #3c5a99;
    --hootsuite-cerulean: #00aeef;
    --instagram-blue: #405de6;
    --linkedin-blue: #0077b5;
    --pinterest-red: #bd081c;
    --twitter-blue: #1da1f2;
    --wordpress-gray: #464646;
    --youtube-red: #ff0000;

    --oracle-red: #f80000;
    --eloqua-campaign-red: #e95b54;
    --eloqua-folder-blue: #309fdb;
    --eloqua-template-blue: #5a68ad;

    --tfs-green: #41a85f;
    --tfs-dark-green: #2b6e3e;

    --froala-light-gray: #cccccc;
}
