.font-10 {
    font-size: var(--r1-font-10);
}

.font-12 {
    font-size: var(--r1-font-12);
}

.font-14 {
    font-size: var(--r1-font-14);
}

.font-16 {
    font-size: var(--r1-font-16);
}

.font-18 {
    font-size: var(--r1-font-18);
}

.font-20 {
    font-size: var(--r1-font-20);
}

.font-24 {
    font-size: var(--r1-font-24);
}

