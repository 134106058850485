@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
    background-color: var(--r1-white);
    font-family: var(--r1-font-family);
    * :not(mat-icon) {
        font-family: var(--r1-font-family) !important;
    }
    margin: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.page-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
}

a {
    color: var(--r1-brilliant-blue-2018);
    text-decoration: none;
    cursor: pointer;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

p {
    font-size: var(--r1-font-12);
}

// mdc15 - classes may change, this is getting rid of padding on top which makes the darker heading not have white space above
// Target's the `backdropClass` of a `mat-menu-panel`
// https://v8.material.angular.io/components/menu/api#directives
// prettier-ignore
.backdrop-hack+* .mat-mdc-menu-panel {
    .mat-mdc-menu-content:not(:empty) {
        padding: 0px;
    }
}
