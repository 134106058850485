.button {
    min-height: 30px;
    min-width: 60px;
    border-radius: 3px;
    font-size: 12px;
    text-align: center;

    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:active, &:visited, &:focus {
        outline: none;
    }
}

.button-black {
    background: var(--r1-black);
    color: var(--r1-white);
    border: solid 1px var(--r1-black);
}

.button-white {
    background: var(--r1-white);
    color: var(--r1-black);
    border: solid 1px var(--r1-smoke);
}

.button-brilliant-blue {
    background: var(--r1-brilliant-blue);
    color: var(--r1-white);
    border: solid 1px var(--r1-brilliant-blue);
}

.button-light-blue {
    background: var(--r1-light-blue);
    color: var(--r1-white);
    border: solid 1px var(--r1-light-blue);
}

.button-black:disabled, .button-brilliant-blue:disabled, .button-light-blue:disabled {
    background: var(--r1-smoke);
    color: var(--r1-white);
    border: solid 1px var(--r1-smoke);
    cursor: not-allowed;
}
